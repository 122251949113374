@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .shadow__black{
        @apply shadow-md shadow-black/5
    }

    .btn_darkmode{
        @apply px-4 py-2 rounded-full transition-colors duration-200
    }
    .btn_darkmode_on{
        @apply bg-gray-800 text-white
    }
    .btn_darkmode_off{
        @apply bg-yellow-400 text-gray-900
    }
}